import * as React from 'react';
import { Cards, Hero, Footer } from '../components/elements/index';
import PageSeo from '../components/elements/Seo/PageSeo';
import hcp from '../copy/hcp';
import PageMeta from '../metadata/hcp';

const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={PageMeta} />
      <Hero heroData={hcp}/>
      <Cards cardAudience={hcp.cards}  />
      <Footer footerAudience={hcp}/>
    </>
  )
}

export default IndexPage;
